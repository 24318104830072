import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: { Accept: "application/json", "Content-Type": "application/json" },
	withCredentials: true,
})

interface IState {
	loadingPercent: number
}

const initialState: IState = {
	loadingPercent: null,
}

export const AppSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setLoadingPercent(state, action: PayloadAction<number>) {
			state.loadingPercent = action.payload
		},
	},
	extraReducers: {},
})

export default AppSlice
