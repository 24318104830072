import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/state/hooks/redux"
import AuthSlice, { registerBasedFromGuest, registerByPassword, verifyEmailThunk } from "src/state/reducers/auth.slice"
import { BackLink, HelpText, InputField, InputTitle, SubmitButton, VerifyEmailContent } from "./styles"

import { notification } from "antd"

export enum VerifyEmailModes {
	Based = "based",
	Guest = "guest",
}

const VerifyEmail = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { nickname, isPendind, registerSuccess } = useAppSelector(state => state.authReducer)
	const { messageError, activateCode } = useAppSelector(state => state.authReducer.verifyEmail)

	const [searchParams] = useSearchParams()
	const mode = searchParams.get("mode") as VerifyEmailModes | undefined

	function onChangeCode(newCode: string) {
		dispatch(AuthSlice.actions.setActivateCode(newCode))
	}

	function onSubmit() {
		if (isPendind) {
			notification.warning({ message: "Loading...", duration: 1 })
			return
		}
		if (!activateCode) {
			notification.warning({ message: "Field is empty!", duration: 1 })
			return
		}

		switch (mode) {
			case VerifyEmailModes.Based:
				dispatch(registerByPassword())
				break
			case VerifyEmailModes.Guest:
				dispatch(registerBasedFromGuest())
				break

			default:
				dispatch(registerByPassword())
				break
		}
	}

	function onBackLink() {
		window.location.pathname = "/signup"
	}

	useEffect(() => {
		if (!nickname) {
			navigate("/signup")
		} else {
			dispatch(verifyEmailThunk())
		}
	}, [nickname])

	useEffect(() => {
		if (registerSuccess) {
			navigate("/verify-email/success")
		}
	}, [registerSuccess])

	return (
		<VerifyEmailContent>
			<InputField>
				<InputTitle>The code from the message</InputTitle>
				<input type="text" onChange={e => onChangeCode(e?.target?.value)} value={activateCode || ""} />
				<HelpText>{!isPendind && (messageError || "")}</HelpText>
			</InputField>
			<SubmitButton onClick={onSubmit} disabled={isPendind}>
				SUBMIT
			</SubmitButton>
			<BackLink onClick={onBackLink}>Enter another email</BackLink>
		</VerifyEmailContent>
	)
}

export default VerifyEmail
