import { IUser } from "./auth"

export namespace CheckRegisterBased {
	export const path = "/users/register/based/check"
	export class Request {
		email: string
		nickname: string
		password: string
	}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}
export namespace RegisterBased {
	export const path = "/users/register/based"
	export class Request {
		email: string
		nickname: string
		password: string
		activateCode: string
	}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace RegisterGuest {
	export const path = "/users/register/guest"
	export class Request {}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace RegisterBasedFromGuest {
	export const path = "/users/register/guest_to_based"
	export class Request {
		nickname: string
		email: string
		password: string
		activateCode: string
	}
	export class Response {}
	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace VerifyEmail {
	export const path = "/users/register/verify-email"
	export class Request {
		email: string
	}
	export class Response {
		success: boolean
	}
}
