import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { notification } from "antd"
import axios, { AxiosError, AxiosResponse } from "axios"
import { AuthBased, AuthJWT, AuthTonPlay, IUser, Logout } from "src/interfaces/requests/auth"
import { CheckRegisterBased, RegisterBased, RegisterBasedFromGuest, RegisterGuest, VerifyEmail } from "src/interfaces/requests/register"
import fast_debug from "src/utils/fast_debug"

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	headers: { Accept: "application/json", "Content-Type": "application/json" },
	withCredentials: true,
})

// REGISTER START
export const checkRegisterByPassword = createAsyncThunk("checkRegisterByPassword", async (payload, { getState, rejectWithValue }) => {
	try {
		const state = getState() as any
		const { email, password, nickname } = state.authReducer
		return await axiosInstance
			.post<{}, AxiosResponse<CheckRegisterBased.Response>, CheckRegisterBased.Request>(CheckRegisterBased.path, { email, password, nickname })
			.then(res => res.data)
	} catch (e) {
		console.log(e)
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})
export const registerByPassword = createAsyncThunk("registerByPassword", async (payload, { getState, rejectWithValue }) => {
	try {
		const state = getState() as any
		const { email, password, nickname } = state.authReducer
		const { activateCode } = state.authReducer.verifyEmail
		return await axiosInstance
			.post<{}, AxiosResponse<RegisterBased.Response>, RegisterBased.Request>(RegisterBased.path, { email, password, nickname, activateCode })
			.then(res => res.data)
	} catch (e) {
		console.log(e)
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

export const registerGuest = createAsyncThunk("registerGuest", async (payload, { getState, rejectWithValue }) => {
	try {
		return await axiosInstance.post<{}, AxiosResponse<RegisterGuest.Response>, RegisterGuest.Request>(RegisterGuest.path, {}).then(res => res.data)
	} catch (e) {
		console.log(e)
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

export const registerBasedFromGuest = createAsyncThunk("registerBasedFromGuest", async (payload, { getState, rejectWithValue }) => {
	try {
		const state = getState() as any
		const { email, password, nickname } = state.authReducer
		const { activateCode } = state.authReducer.verifyEmail
		return await axiosInstance
			.post<{}, AxiosResponse<RegisterBasedFromGuest.Response>, RegisterBasedFromGuest.Request>(RegisterBasedFromGuest.path, { email, nickname, password, activateCode })
			.then(res => res.data)
	} catch (e) {
		console.log(e)
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

export const verifyEmailThunk = createAsyncThunk("verifyEmailThunk", async (payload, { getState, rejectWithValue }) => {
	try {
		const state = getState() as any
		const { email } = state.authReducer
		return await axiosInstance.post<{}, AxiosResponse<VerifyEmail.Response>, VerifyEmail.Request>(VerifyEmail.path, { email }).then(res => res.data)
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

// REGISTER END

// AUTH START
export const authByPassword = createAsyncThunk("authByPassword", async (payload, { getState, rejectWithValue }) => {
	try {
		const state = getState() as any
		const { email, password } = state.authReducer
		return await axiosInstance.post<{}, AxiosResponse<AuthBased.Response>, AuthBased.Request>(AuthBased.path, { email, password }).then(res => res.data)
	} catch (e) {
		console.log(e)
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

export const authByJWT = createAsyncThunk("authByJWT", async (payload: AuthJWT.Request, { getState, rejectWithValue }) => {
	try {
		return await axiosInstance.get<{}, AxiosResponse<AuthJWT.Response>, AuthJWT.Request>(AuthJWT.path, {}).then(res => res.data)
	} catch (e) {
		console.log(e)
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e?.message)
		}
		return
	}
})

// export const authByGuest = createAsyncThunk("authByGuest", async (payload: AuthGuest.Request, { getState, rejectWithValue }) => {
// 	try {
// 		return await axiosInstance.post<{}, AxiosResponse<AuthGuest.Response>, AuthGuest.Request>(AuthGuest.path, {}).then(res => res.data)
// 	} catch (e) {
// 		if (axios.isAxiosError(e)) {
// 			return rejectWithValue(e)
// 		}
// 		return
// 	}
// })

export const authByTonPlayToken = createAsyncThunk("authByTonPlayToken", async (payload: AuthTonPlay.Request, { getState, rejectWithValue }) => {
	try {
		if (!payload.token) {
			fast_debug("Нет токена")
			return rejectWithValue("Нет токена")
		}
		return await axiosInstance.post<{}, AxiosResponse<AuthTonPlay.Response>, AuthTonPlay.Request>(AuthTonPlay.path, { token: payload.token }).then(res => res.data)
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})

export const logout = createAsyncThunk("logout", async (payload, { getState, rejectWithValue }) => {
	try {
		const res = await axiosInstance.get<{}, AxiosResponse<Logout.Response>, Logout.Request>(Logout.path, {}).then(res => res.data)
		// window.location.reload()
		return res
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e)
		}
		return
	}
})
// AUTH END

interface IState {
	registerBasedFromGuestSuccess: boolean
	verifyEmail: { success: boolean; isLoading: boolean; messageError: string; activateCode: string }

	isTonPlayUser: boolean
	isTonPlayAuthError: boolean
	isTryedAuthJWT: boolean
	isPendind: boolean
	isSuccess: boolean
	isSuccessCheck: boolean
	registerSuccess: boolean

	email: string
	password: string
	nickname: string
	emailHelpText: string
	passwordHelpText: string
	nicknameHelpText: string

	user: IUser
	tokens: {
		access_token: string
		refresh_token: string
	}
}

const initialState: IState = {
	registerBasedFromGuestSuccess: null,
	verifyEmail: { success: null, messageError: null, isLoading: false, activateCode: null },

	isTonPlayUser: null,
	isTonPlayAuthError: null,
	isTryedAuthJWT: false,
	isPendind: false,
	isSuccess: false,
	isSuccessCheck: false,
	registerSuccess: false,

	email: null,
	password: null,
	nickname: null,

	emailHelpText: null,
	passwordHelpText: null,
	nicknameHelpText: null,

	user: null,
	tokens: {
		access_token: null,
		refresh_token: null,
	},
}

export const AuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setEmail(state, action: PayloadAction<string>) {
			state.email = action.payload
		},
		setEmailHelpText(state, action: PayloadAction<string>) {
			state.emailHelpText = action.payload
		},
		setPassword(state, action: PayloadAction<string>) {
			state.password = action.payload
		},
		setPasswordHelpText(state, action: PayloadAction<string>) {
			state.passwordHelpText = action.payload
		},
		setNickname(state, action: PayloadAction<string>) {
			state.nickname = action.payload
		},
		setNicknameHelpText(state, action: PayloadAction<string>) {
			state.nicknameHelpText = action.payload
		},
		setIsTonPlayUser(state, action: PayloadAction<boolean>) {
			state.isTonPlayUser = action.payload
		},
		setActivateCode(state, action: PayloadAction<string>) {
			state.verifyEmail.activateCode = action.payload
		},
	},
	extraReducers: {
		// ---
		[checkRegisterByPassword.pending.type]: (state, action: PayloadAction) => {
			state.isPendind = true
		},
		[checkRegisterByPassword.rejected.type]: (state, action: PayloadAction<AxiosError<RegisterGuest.ResponseError>>) => {
			state.isPendind = false
			state.isSuccessCheck = false

			const errorMessage = action?.payload?.response?.data?.message
			if (Array.isArray(errorMessage)) {
				errorMessage.forEach(errorText => {
					errorText = errorText?.toLocaleLowerCase()
					if (errorText?.startsWith("password")) {
						state.passwordHelpText = errorText.replace("password ", "")
					} else if (errorText?.startsWith("email")) {
						state.emailHelpText = errorText.replace("email ", "")
					} else if (errorText?.startsWith("nickname")) {
						state.nicknameHelpText = errorText.replace("nickname ", "")
					} else {
						notification.error({ message: errorText })
					}
				})
			} else {
				if (errorMessage?.startsWith("User with email")) {
					state.emailHelpText = errorMessage
				} else {
					notification.error({ message: errorMessage })
				}
			}
		},
		[checkRegisterByPassword.fulfilled.type]: (state, action: PayloadAction<RegisterGuest.Response>) => {
			state.isPendind = false
			state.isSuccessCheck = true
		},
		// ---
		[registerGuest.pending.type]: (state, action: PayloadAction) => {
			state.isPendind = true
		},
		[registerGuest.rejected.type]: (state, action: PayloadAction<AxiosError<RegisterGuest.ResponseError>>) => {
			state.isPendind = false
			state.isSuccess = false
			notification.error({ message: action?.payload?.response?.data?.message })
		},
		[registerGuest.fulfilled.type]: (state, action: PayloadAction<RegisterGuest.Response>) => {
			state.isPendind = false
			state.isSuccess = true
			state.tokens.access_token = action.payload.access_token
			state.tokens.refresh_token = action.payload.refresh_token
			state.user = action.payload.user
		},
		// ---
		[registerByPassword.pending.type]: (state, action: PayloadAction) => {
			state.isPendind = true
		},
		[registerByPassword.rejected.type]: (state, action: PayloadAction<AxiosError<RegisterBased.ResponseError>>) => {
			state.isPendind = false
			state.isSuccess = false
			state.registerSuccess = false

			const errorMessage = action?.payload?.response?.data?.message
			if (Array.isArray(errorMessage)) {
				errorMessage.forEach(errorText => {
					errorText = errorText?.toLocaleLowerCase()
					fast_debug(errorText)
					if (errorText?.startsWith("password")) {
						state.passwordHelpText = errorText.replace("password ", "")
					} else if (errorText?.startsWith("email")) {
						state.emailHelpText = errorText.replace("email ", "")
					} else if (errorText?.startsWith("nickname")) {
						state.nicknameHelpText = errorText.replace("nickname ", "")
					} else {
						notification.error({ message: errorText })
					}
				})
			} else {
				if (errorMessage?.startsWith("User with email")) {
					state.emailHelpText = errorMessage
				} else if (errorMessage?.startsWith("Activate")) {
					state.verifyEmail.messageError = errorMessage.replace("Activate ", "")
				} else {
					notification.error({ message: errorMessage })
				}
			}
		},
		[registerByPassword.fulfilled.type]: (state, action: PayloadAction<RegisterBased.Response>) => {
			state.isPendind = false
			state.isSuccess = true
			state.registerSuccess = true
			state.tokens.access_token = action.payload.access_token
			state.tokens.refresh_token = action.payload.refresh_token
			state.user = action.payload.user
		},
		// ---
		[registerBasedFromGuest.pending.type]: (state, action: PayloadAction) => {
			state.isPendind = true
		},
		[registerBasedFromGuest.rejected.type]: (state, action: PayloadAction<AxiosError<RegisterBasedFromGuest.ResponseError>>) => {
			state.isPendind = false
			state.isSuccess = false
			state.registerSuccess = false

			const errorMessage = action?.payload?.response?.data?.message
			if (Array.isArray(errorMessage)) {
				errorMessage.forEach(errorText => {
					errorText = errorText?.toLocaleLowerCase()
					if (errorText?.startsWith("password")) {
						state.passwordHelpText = errorText.replace("password ", "")
					} else if (errorText?.startsWith("email")) {
						state.emailHelpText = errorText.replace("email ", "")
					} else if (errorText?.startsWith("nickname")) {
						state.nicknameHelpText = errorText.replace("nickname ", "")
					} else {
						notification.error({ message: errorText })
					}
				})
			} else {
				if (errorMessage?.startsWith("User with email")) {
					state.emailHelpText = errorMessage
				} else if (errorMessage?.startsWith("Activate")) {
					state.verifyEmail.messageError = errorMessage.replace("Activate ", "")
				} else {
					notification.error({ message: errorMessage })
				}
			}
		},
		[registerBasedFromGuest.fulfilled.type]: (state, action: PayloadAction<RegisterBasedFromGuest.Response>) => {
			state.isPendind = false
			state.isSuccess = true
			state.registerSuccess = true
		},
		// ---
		[verifyEmailThunk.pending.type]: (state, action: PayloadAction) => {
			state.verifyEmail.isLoading = true
		},
		[verifyEmailThunk.rejected.type]: (state, action: PayloadAction<AxiosError<RegisterBasedFromGuest.ResponseError>>) => {
			state.verifyEmail.isLoading = false
			state.verifyEmail.success = false

			const errorMessage = action?.payload?.response?.data?.message
			if (!Array.isArray(errorMessage)) {
				state.verifyEmail.messageError = errorMessage
			}

			switch (errorMessage) {
				case "The user is not Based.":
					break
				case "The еmail has already been confirmed.":
					break
				case "Invalid activate code.":
					break

				default:
					break
			}
		},
		[verifyEmailThunk.fulfilled.type]: (state, action: PayloadAction<RegisterBasedFromGuest.Response>) => {
			state.verifyEmail.messageError = null
			state.verifyEmail.isLoading = false
			state.verifyEmail.success = true
		},

		// ---
		[authByPassword.pending.type]: (state, action: PayloadAction) => {
			state.isPendind = true
		},
		[authByPassword.rejected.type]: (state, action: PayloadAction<AxiosError<AuthBased.ResponseError>>) => {
			state.isPendind = false
			state.isSuccess = false

			const errorMessage = action?.payload?.response?.data?.message
			if (Array.isArray(errorMessage)) {
				errorMessage.forEach(errorText => {
					errorText = errorText?.toLocaleLowerCase()
					if (errorText?.startsWith("password")) {
						state.passwordHelpText = errorText.replace("password ", "")
					} else if (errorText?.startsWith("email")) {
						state.emailHelpText = errorText.replace("email ", "")
					} else if (errorText?.startsWith("nickname")) {
						state.nicknameHelpText = errorText.replace("nickname ", "")
					} else {
						notification.error({ message: errorText })
					}
				})
			} else {
				if (errorMessage?.startsWith("Invalid")) {
					state.passwordHelpText = errorMessage
					state.emailHelpText = errorMessage
				} else {
					notification.error({ message: errorMessage })
				}
			}
		},
		[authByPassword.fulfilled.type]: (state, action: PayloadAction<AuthBased.Response>) => {
			state.isPendind = false
			state.isSuccess = true

			// state.tokens.access_token = action.payload.access_token
			// state.tokens.refresh_token = action.payload.refresh_token
			// state.user = action.payload.user
		},
		// ---
		[authByJWT.pending.type]: (state, action: PayloadAction) => {
			state.isTryedAuthJWT = false
			state.isPendind = true
		},
		[authByJWT.rejected.type]: (state, action: PayloadAction<AxiosError<AuthJWT.ResponseError>>) => {
			state.isPendind = false
			state.isTryedAuthJWT = true
			state.isSuccess = false
			// notification.error({ message: action?.payload?.response?.data?.message })
		},
		[authByJWT.fulfilled.type]: (state, action: PayloadAction<AuthJWT.Response>) => {
			state.isPendind = false
			state.tokens.access_token = action.payload.access_token
			state.tokens.refresh_token = action.payload.refresh_token
			state.user = action.payload.user
			state.isTryedAuthJWT = true
			state.isSuccess = true
		},
		// ---
		[authByTonPlayToken.pending.type]: state => {
			state.isPendind = true
		},
		[authByTonPlayToken.rejected.type]: (state, action: PayloadAction<AxiosError<AuthTonPlay.ResponseError>>) => {
			state.isPendind = false
			const errorMessage = action?.payload?.response?.data?.message
			notification.error({ message: errorMessage })
			state.isTonPlayAuthError = true
			if (errorMessage === "TonPlay user anonymous") {
			}
		},
		[authByTonPlayToken.fulfilled.type]: (state, action: PayloadAction<AuthTonPlay.Response>) => {
			state.isPendind = false
			state.isTonPlayAuthError = false
			state.isSuccess = true
			state.tokens.access_token = action.payload.access_token
			state.tokens.refresh_token = action.payload.refresh_token
			state.user = action.payload.user
		},
	},
})

export default AuthSlice
