import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "src/state/hooks/redux"
import { logout } from "src/state/reducers/auth.slice"

const Logout = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(logout()).then(() => {
			navigate("/")
		})
	}, [])
	return <div></div>
}

export default Logout
