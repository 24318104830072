import { FC } from "react"
import { useAppSelector } from "src/state/hooks/redux"
import BorderField from "../../assets/BorderField.png"
import { BorderBlock, GameLayoutContent, GameLayoutWrapper, LoadingBar, Percentages } from "./styles"

interface GameLayoutProps {
	children: React.ReactNode
}

const GameLayout: FC<GameLayoutProps> = ({ children }) => {
	const { loadingPercent } = useAppSelector(state => state.appReducer)

	return (
		<GameLayoutWrapper>
			<GameLayoutContent isLoaded={loadingPercent >= 100}>
				{children}
				{loadingPercent < 100 && (
					<LoadingBar>
						<BorderBlock percents={loadingPercent || 0}>
							<img src={BorderField} alt="" />
						</BorderBlock>
						<Percentages>LOADING</Percentages>
					</LoadingBar>
				)}
			</GameLayoutContent>
		</GameLayoutWrapper>
	)
}

export default GameLayout
