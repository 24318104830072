import styled from "styled-components"

export const VerifyEmailSuccessContent = styled.div`
	display: grid;
	place-items: center;
	gap: 30px;

	margin-top: 30px;

	font-size: 40px;
`

export const VerifyEmailSuccessText = styled.div`
	background-color: green;
	padding: 20px;
	border-radius: 20px;
`
export const VerifyEmailSuccessButton = styled.div`
	font-weight: 400;

	padding: 10px 20px;
	background-color: rgba(255, 255, 255, 0.1);

	cursor: pointer;
`
