import { styled } from "styled-components"

export const GamePageWrapper = styled.div`
	display: grid;
	height: 100%;
`

export const RotateBlock = styled.div`
	position: absolute;
	
	left: 0;
	top: 0;
	
	width: 100dvw;
	height: 100dvh;

	display: grid;
	place-content: center;

	background-color: white;

	z-index: 200;

	svg {
		width: 100px;
		height: 120px;
		display: block;
		margin: auto;
	}
`

export const RotateText = styled.div`
	font-size: max(2vw, 18px);
	margin-top: 8px;
`

export const GamePageContent = styled.div<{ showGame: boolean }>`
	display: grid;
	height: 100%;
	place-items: center;

	${({ showGame }) =>
		showGame &&
		`
			height: 100dvh;
			width: 100dvw;
		`}

	> canvas {
		${({ showGame }) =>
			showGame &&
			`
				height: 100dvh;
				width: 100dvw;
			`}

		opacity: ${({ showGame }) => (showGame ? "1" : "0")};
		z-index: 3;
	}
`
