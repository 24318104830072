export interface IGameVersion {
	id?: number
	name: string
	url: string
	description: string
	users?: IUser[]
}
export interface IUser {
	id?: number
	nickname: string
	user_address: string
	isBasedUser?: boolean
	isGuestUser?: boolean
	isTonPlayUser?: boolean
	telegram_info?: IUserTonPlayInfo
	based_info?: IUserBasedInfo
	// Поле не требуется
	// games?: IGame[]
	version?: IGameVersion
}

export interface IUserBasedInfo {
	id?: number
	email: string
	password: string
}

export interface IUserTonPlayInfo {
	id?: number
	tonplay_username: string
	tonplay_avatar: string
	telegram_id: number
	tonplay_id: string
	tonplay_token: string
}

export namespace AuthBased {
	export const path = "/users/auth/based"
	export class Request {
		email: string
		password: string
	}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace AuthTonPlay {
	export const path = "/users/auth/tonplay"
	export class Request {
		token: string
	}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace AuthGuest {
	export const path = "/users/auth/guest"
	export class Request {}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace AuthJWT {
	export const path = "/users/auth/jwt"
	export class Request {}
	export class Response {
		user: IUser
		access_token: string
		refresh_token: string
	}

	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}

export namespace Logout {
	export const path = "/users/auth/logout"
	export class Request {}
	export class Response {
		success: true
	}
	export class ResponseError {
		message: string | string[]
		statusCode: number
	}
}
