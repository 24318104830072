import { notification } from "antd"
import FontFaceObserver from "fontfaceobserver"
import { useEffect, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import AppRouter from "./routes/AppRouter"
import SafariWarning from "./pages/404/SafariWarning"
import PingBlock from "./components/PingBlock"

function App() {
	const [fontsLoaded, setFontsLoaded] = useState(false)
	const [isSafari, setIsSafari] = useState(false)

	useEffect(() => {
		const userAgent = window.navigator.userAgent
		setIsSafari(
			userAgent.includes("Safari") &&
				!userAgent.includes("Chrome") &&
				!userAgent.includes("YaBrowser") &&
				!userAgent.includes("Opera") &&
				!userAgent.includes("iPhone") &&
				!userAgent.includes("iPad")
		)
	}, [])

	useEffect(() => {
		const font = new FontFaceObserver("Teko")

		font.load().then(() => {
			setFontsLoaded(true)
		})
	}, [])

	notification.config({ duration: 2 })

	const theme = {
		mainBackgroundColor: "black",
	}

	if (!fontsLoaded) return <></>

	// if (isSafari) return <SafariWarning />

	return (
		<ThemeProvider theme={theme}>
			<Router>
				{/* <PingBlock /> */}
				<AppRouter />
			</Router>
		</ThemeProvider>
	)
}

export default App
