import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "src/state/hooks/redux"
import AuthSlice, { authByPassword } from "src/state/reducers/auth.slice"
import { RegisterBlock } from "./styles"
import { useEffect, useState } from "react"
import fast_debug from "src/utils/fast_debug"
import { InputField, InputTitle, HelpText, HaveAccount, HelpButton, SubmitButton, Divider } from "../SignUp/styles"
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"

const SignIn = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { email, password, emailHelpText, passwordHelpText, isPendind, user, tokens, isSuccess } = useAppSelector(state => state.authReducer)

	const [showPassword, setShowPassword] = useState(false)

	const onChangeEmail = (email: string) => {
		dispatch(AuthSlice.actions.setEmailHelpText(""))
		dispatch(AuthSlice.actions.setEmail(email))
	}
	const onChangePassword = (email: string) => {
		dispatch(AuthSlice.actions.setPasswordHelpText(""))
		dispatch(AuthSlice.actions.setPassword(email))
	}

	const onSubmit = () => {
		if (isPendind) return
		if (!email || !password) {
			if (!email) {
				dispatch(AuthSlice.actions.setEmailHelpText("Field is required"))
			}
			if (!password) {
				dispatch(AuthSlice.actions.setPasswordHelpText("Field is required"))
			}
			return
		}
		dispatch(authByPassword())
	}

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			onSubmit()
		}
	}

	useEffect(() => {
		if (isSuccess) {
			navigate("/")
		}
	}, [isSuccess])

	return (
		<RegisterBlock onKeyDown={handleKeyPress}>
			<Divider />
			<InputField>
				<InputTitle>Email</InputTitle>
				<input type="text" autoFocus onChange={e => onChangeEmail(e?.target?.value)} value={email || ""} />
				<HelpText>{emailHelpText || ""}</HelpText>
			</InputField>
			<Divider />
			<InputField>
				<InputTitle>Password</InputTitle>
				{showPassword ? <EyeInvisibleOutlined onClick={() => setShowPassword(false)} /> : <EyeOutlined onClick={() => setShowPassword(true)} />}
				<input type={showPassword ? "text" : "password"} onChange={e => onChangePassword(e?.target?.value)} value={password || ""} />
				<HelpText>{passwordHelpText || ""}</HelpText>
			</InputField>
			<SubmitButton onClick={onSubmit} disabled={isPendind}>
				SIGN IN
			</SubmitButton>
			<Divider />
			<HaveAccount>
				Do not have account?
				<HelpButton onClick={() => (window.location.pathname = "/signup")}>SIGN UP</HelpButton>
			</HaveAccount>
		</RegisterBlock>
	)
}

export default SignIn
