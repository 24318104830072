import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons"
import { notification } from "antd"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Loading from "src/components/Loading"
import { VerifyEmailModes } from "src/components/VerifyEmail"
import { useAppDispatch, useAppSelector } from "src/state/hooks/redux"
import AuthSlice, { authByJWT, checkRegisterByPassword } from "src/state/reducers/auth.slice"
import { RegisterBlock } from "../SignIn/styles"
import { Divider, HelpText, InputField, InputTitle, NoAuthMessage, SubmitButton } from "./styles"

const GuestToBased = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { isTryedAuthJWT, email, password, emailHelpText, passwordHelpText, nicknameHelpText, isPendind, nickname, user, isSuccessCheck } = useAppSelector(
		state => state.authReducer
	)

	const [showPassword, setShowPassword] = useState(false)

	const onChangeEmail = (email: string) => {
		dispatch(AuthSlice.actions.setEmailHelpText(""))
		dispatch(AuthSlice.actions.setEmail(email))
	}
	const onChangePassword = (email: string) => {
		dispatch(AuthSlice.actions.setPasswordHelpText(""))
		dispatch(AuthSlice.actions.setPassword(email))
	}
	const onChangeNickname = (nickname: string) => {
		const regex = /[^a-zA-Z0-9._ -]/g

		const value = nickname

		const newValue = value?.replace(regex, "")
		const removedPart = value?.replace(newValue, "")

		if (newValue.length !== value.length) {
			notification.error({
				message: (
					<>
						Symbol <strong>"{removedPart}"</strong>
						forbidden!
					</>
				),
				duration: 1.5,
			})
		}
		nickname?.replace(/[^a-zA-Z0-9_]/g, "_")
		dispatch(AuthSlice.actions.setNicknameHelpText(""))
		dispatch(AuthSlice.actions.setNickname(newValue))
	}

	const onSubmit = () => {
		if (isPendind) return
		if (!email || !password || !nickname) {
			if (!email) {
				dispatch(AuthSlice.actions.setEmailHelpText("Field is required"))
			}
			if (!password) {
				dispatch(AuthSlice.actions.setPasswordHelpText("Field is required"))
			}
			if (!nickname) {
				dispatch(AuthSlice.actions.setNicknameHelpText("Field is required"))
			}
			return
		}

		dispatch(checkRegisterByPassword())
	}

	const handleKeyPress = event => {
		if (event.key === "Enter") {
			onSubmit()
		}
	}

	useEffect(() => {
		dispatch(authByJWT({}))
	}, [])

	useEffect(() => {
		if (isSuccessCheck) {
			navigate(`/verify-email/?mode=${VerifyEmailModes.Guest}`)
		}
	}, [isSuccessCheck])

	if (!isTryedAuthJWT && !user) {
		return <Loading />
	} else if (isTryedAuthJWT && !user) {
		return (
			<NoAuthMessage>
				<div>You are not logged in</div>
			</NoAuthMessage>
		)
	} else if (!user.isGuestUser) {
		return (
			<NoAuthMessage>
				<div>Your account is not a guest account</div>
			</NoAuthMessage>
		)
	}

	return (
		<RegisterBlock onKeyDown={handleKeyPress}>
			<Divider />
			<InputField inputDisable>
				<InputTitle>Old guest nickname</InputTitle>
				<input type="text" onChange={e => e} value={user.nickname} disabled />
			</InputField>
			<Divider />
			<InputField>
				<InputTitle>New nickname</InputTitle>
				<input type="text" onChange={e => onChangeNickname(e?.target?.value)} value={nickname} />
				<HelpText>{nicknameHelpText || ""}</HelpText>
			</InputField>
			<Divider />
			<InputField>
				<InputTitle>Email</InputTitle>
				<input type="text" autoFocus onChange={e => onChangeEmail(e?.target?.value)} value={email} />
				<HelpText>{emailHelpText || ""}</HelpText>
			</InputField>
			<Divider />
			<InputField>
				<InputTitle>Password</InputTitle>
				{showPassword ? <EyeInvisibleOutlined onClick={() => setShowPassword(false)} /> : <EyeOutlined onClick={() => setShowPassword(true)} />}
				<input type={showPassword ? "text" : "password"} onChange={e => onChangePassword(e?.target?.value)} value={password} />
				<HelpText>{passwordHelpText || ""}</HelpText>
			</InputField>

			<SubmitButton onClick={onSubmit} disabled={isPendind}>
				SIGN UP
			</SubmitButton>
		</RegisterBlock>
	)
}

export default GuestToBased
