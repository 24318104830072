import styled from "styled-components"

export const VerifyEmailContent = styled.div`
	margin-top: 50px;
	display: grid;
	place-items: center;

	font-size: 20px;
`

export const InputField = styled.div`
	position: relative;
	display: grid;
	grid-auto-flow: row;
	justify-items: center;
	width: 100%;
	input {
		width: 100%;

		font-size: 25px;
		font-weight: 500;

		padding: 5px;

		text-align: center;

		background-color: ${props => props.theme.mainBackgroundColor};
		border: 1px white solid;
		color: white;

		@media only screen and (max-device-width: 480px) {
			font-size: 60px;
			width: auto;
		}
	}

	svg {
		position: absolute;
		top: 40px;
		right: 10px;

		opacity: 0.5;

		width: 25px;
		height: 25px;
	}
`

export const InputTitle = styled.div`
	margin-bottom: 10px;
	font-size: 25px;
	line-height: 20px;
	font-weight: 500;
	text-align: center;

	@media only screen and (max-device-width: 480px) {
		line-height: 40px;
		font-size: 60px;
	}
`

export const SubmitButton = styled.button`
	font-size: 30px;
	font-weight: 400;

	padding: 10px 20px;
	background-color: rgba(255, 255, 255, 0.1);

	@media only screen and (max-device-width: 480px) {
		font-size: 65px;
		padding: 15px 30px;
	}
`
export const SkipButton = styled(SubmitButton)`
	background-color: transparent;
`

export const HelpText = styled.div`
	margin-top: 10px;
	color: red;
	font-size: 24px;

	min-height: 24px;

	white-space: pre-wrap;
	text-align: center;

	@media only screen and (max-device-width: 480px) {
		font-size: 40px;
	}
`

export const BackLink = styled.div`
	margin-top: 30px;
	font-size: 20px;
	position: relative;
	text-decoration: underline;

	cursor: pointer;
`
