import { styled } from "styled-components"
import BackgroundFull from "../../../assets/BackgroundFull.webp"

export const SafariWarningWrapper = styled.div`
	position: relative;
	height: 100dvh;
	padding: 0 5dvw;

	display: grid;
	grid-template-rows: 1fr auto 1fr;
	place-items: center;

	background: url(${BackgroundFull});
	background-size: cover;
	background-position: center;
`

export const SafariWarningMessage = styled.div`
	font-size: 3vw;
	color: white;
	text-align: center;

	@media only screen and (max-device-width: 480px) {
		font-size: 5vw;
	}

	white-space: pre-line;
	padding: 20px;

	border-radius: 20px;
	box-shadow: 0 0 10px 15px rgba(0, 0, 0, 0.5);

	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(160, 160, 160);

	span {
		color: white;
	}

	&::before {
		content: "";
		background-color: black;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
`

export const Logo = styled.div`
	img {
		width: 23vw;

		@media only screen and (max-device-width: 480px) {
			width: 33vw;
		}
	}
`
