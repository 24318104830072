import { keyframes, styled } from "styled-components"

export const RegisterBlock = styled.div`
	display: grid;
	place-items: center;
	gap: 20px;

	padding: 30px;

	color: white;

	@media only screen and (max-device-width: 480px) {
		gap: 50px;
	}
`
