import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import { Unity, UnityConfig, useUnityContext } from "react-unity-webgl"
import AppSlice from "src/state/reducers/app.slice"
import { ReactComponent as RotatePhoneIcon } from "../../assets/RotatePhoneIcon.svg"
import { useAppDispatch, useAppSelector } from "../../state/hooks/redux"
import { GamePageContent, GamePageWrapper, RotateBlock, RotateText } from "./styles"
import { useNavigate } from "react-router-dom"

const GamePage = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const { user, tokens } = useAppSelector(state => state.authReducer)
	const { loadingPercent } = useAppSelector(state => state.appReducer)
	const [isAllowOrientation, setOrientation] = useState()

	const config: UnityConfig = {
		loaderUrl: user?.version?.url + "/Build.loader.js",
		dataUrl: user?.version?.url + "/Build.data.gz",
		frameworkUrl: user?.version?.url + "/Build.framework.js.gz",
		codeUrl: user?.version?.url + "/Build.wasm.gz",
		streamingAssetsUrl: user?.version?.url + "/StreamingAssets",
		companyName: "Qubix",
		productName: "Qubix",
		productVersion: "0.1",
		webglContextAttributes: {
			//@ts-ignore
			powerPreference: 2,
		},
		cacheControl: () => "must-revalidate",
	}

	const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, unload, requestFullscreen } = useUnityContext(config)

	useEffect(() => {
		const formatedProgress = +loadingProgression.toFixed(2) * 100
		if (formatedProgress !== loadingPercent) {
			dispatch(AppSlice.actions.setLoadingPercent(formatedProgress))
		}
	}, [loadingProgression])

	// useEffect(() => {
	// 	if (isMobileDevice) {
	// 		window.addEventListener("resize", checkOrientation)
	// 		return () => window.removeEventListener("resize", checkOrientation)
	// 	} else {
	// 		document.getElementById("rotate-block").style.display = "none"
	// 	}
	// }, [])

	if (!user?.version?.url) {
		return <div>User no have version</div>
	}

	window.redirectToLobby = () => {
		navigate('/lobby')
	  }

	window.getToken = () => {
		return Cookies.get("access_token") || tokens.access_token
	}

	const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)

	// Device
	window.getIsMobileDevice = () => isMobileDevice

	// Orientation
	function checkOrientation() {
		document.getElementById("rotate-block").style.display = window.innerHeight > window.innerWidth ? "grid" : "none"
	}

	// Fullscreen
	function launchFullscreenGame(element) {
		if (element.requestFullscreen) element.requestFullscreen()
		else if (element.mozRequestFullScreen) element.mozRequestFullScreen()
		else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen() /* Safari */
		else if (element.msRequestFullscreen) element.msRequestFullscreen() /* IE11 */
	}
	
	function cancelFullscreenGame() {
		if (document.exitFullscreen) document.exitFullscreen()
		// @ts-ignore
		else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
		// @ts-ignore
		else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
		// @ts-ignore
		else if (document.msExitFullscreen) document.msExitFullscreen()
	}
	window.launchFullscreen = () => launchFullscreenGame(document.getElementById("game"))
	window.cancelFullscreen = () => cancelFullscreenGame

	// PlayDeck Scope
	const sdk = window.parent.window
	const urlParams = new URLSearchParams(window.location.search)
	const isTelegram = urlParams.get("telegram") === "true"

	const sendLoading = value => sdk.postMessage({ playdeck: { method: "loading", value } }, "*")
	window.sendLoading = value => sendLoading(value)

	if (isTelegram) {
		window.PlayDeck = { isTelegram, sdk }
		sdk.postMessage({ playdeck: { method: "loading", value: 100 } }, "*") // send starts display loading progress
	}

	return (
		// <GamePageWrapper id="game">
		// 	<RotateBlock id="rotate-block">
		// 		<RotatePhoneIcon />
		// 		<RotateText>Please turn your device to landscape orientation</RotateText>
		// 	</RotateBlock>
		// 	<GamePageContent showGame={isLoaded}>
		// 		<Unity className="unity" unityProvider={unityProvider} />
		// 	</GamePageContent>
		// </GamePageWrapper>
		<GamePageWrapper id="game">
			<GamePageContent showGame={isLoaded}>
				<Unity className="unity" unityProvider={unityProvider} />
			</GamePageContent>
		</GamePageWrapper>
	)
}

export default GamePage
