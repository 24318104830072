import { styled } from "styled-components"
import BackgroundImage from "../../assets/BackgroundFull.webp"
import BackgroundField from "../../assets/BackgroundField.png"
import BorderField from "../../assets/BorderField.png"

export const AuthWrapper = styled.div`
	display: grid;
	height: 100%;

	place-items: center;

	background: no-repeat center url(${BackgroundImage});

	background-clip: padding-box;
	background-attachment: fixed;
	background-size: cover;
	background-color: ${props => props.theme.mainBackgroundColor};
	@media only screen and (max-device-width: 480px) {
		background-size: cover;
	}

	background-color: ${props => props.theme.mainBackgroundColor};
`
export const AuthContent = styled.div`
	display: grid;
	place-items: center;
	height: min-content;

	grid-template-rows: 1fr min-content;
`

export const BackButton = styled.div`
	position: absolute;
	left: 40px;
	top: 40px;
	padding: 10px;
	border-radius: 10px;
	svg {
		width: 50px;
		height: 50px;
		transform: rotate(-90deg);
	}

	cursor: pointer;
`
