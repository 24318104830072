import { useNavigate } from "react-router-dom"
import { VerifyEmailSuccessButton, VerifyEmailSuccessContent, VerifyEmailSuccessText } from "./styles"

const VerifyEmailSuccess = () => {
	const navigate = useNavigate()

	return (
		<VerifyEmailSuccessContent>
			<VerifyEmailSuccessText>Email successfully confirmed!</VerifyEmailSuccessText>
			<VerifyEmailSuccessButton onClick={() => navigate("/")}>Go to Game!</VerifyEmailSuccessButton>
		</VerifyEmailSuccessContent>
	)
}

export default VerifyEmailSuccess
