import { useEffect } from "react"
import { Navigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../src/state/hooks/redux"
import AuthSlice, { authByJWT, authByTonPlayToken, registerGuest } from "src/state/reducers/auth.slice"
import Loading from "../Loading"

const TonPlayAuth = (): string | null => {
	return new URLSearchParams(window.location.search).get("token")
}

const WithAuthHOC = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useAppDispatch()
	const { user, isTryedAuthJWT, isTonPlayAuthError, isSuccess, isTonPlayUser } = useAppSelector(state => state.authReducer)

	const tonPlayToken = TonPlayAuth()
	useEffect(() => {
		if (tonPlayToken) {
			dispatch(AuthSlice.actions.setIsTonPlayUser(true))
			dispatch(authByTonPlayToken({ token: tonPlayToken }))
		} else {
			dispatch(authByJWT({}))
		}
	}, [])

	useEffect(() => {
		if (isTonPlayAuthError && isTonPlayUser) {
			// Если произошла ошибка авторизации TonPlay пользователя
			// Запрос на создание анонимного пользователя
			dispatch(registerGuest())
		}
	}, [isTonPlayAuthError, isTonPlayUser])

	if (isTonPlayUser) {
		// Пользователь TonPlay
		if (isSuccess) {
			// Авторизация успешна
			// Либо через tonplay либо нашим анонимом
			return <>{children}</>
		} else {
			return <Loading />
		}
	} else {
		// Пользователь обычный
		if (!isTryedAuthJWT && !user) {
			return <Loading />
		} else if (isTryedAuthJWT && !user) {
			return <Navigate to="/auth" />
		} else {
			return <>{children}</>
		}
	}
}

export default WithAuthHOC
