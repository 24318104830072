import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Logo } from "src/pages/Auth/styles"
import { ReactComponent as Arrow } from "../../assets/Arrow.svg"
import LogoImage from "../../assets/Logo.png"
import { AuthContent, AuthWrapper, BackButton } from "./styles"

interface AuthLayoutProps {
	children: React.ReactNode
}

const AuthLayout: FC<AuthLayoutProps> = ({ children }) => {
	const navigate = useNavigate()

	return (
		<AuthWrapper>
			<AuthContent>
				<Logo>
					<img src={LogoImage} alt="" />
				</Logo>
				<BackButton onClick={() => navigate("/auth")}>
					<Arrow />
				</BackButton>
				{children}
			</AuthContent>
		</AuthWrapper>
	)
}

export default AuthLayout
