import { styled } from "styled-components"
import BackgroundLoading from "../../assets/BackgroundLoading.webp"

export const LoadingWrapper = styled.div`
	height: 100%;
`
export const LoadingContent = styled.div`
	height: 100%;
	background: no-repeat center url(${BackgroundLoading});

	background-clip: padding-box;
	background-attachment: fixed;
	background-size: contain;
	background-color: ${props => props.theme.mainBackgroundColor};

	display: grid;
	place-items: center;
`
