import { SafariWarningWrapper, SafariWarningMessage, Logo } from "./styles"
import LogoImage from "../../../assets/Logo.png"

const SafariWarning = () => {
	return (
		<SafariWarningWrapper>
			<Logo>
				<img src={LogoImage} alt="" />
			</Logo>
			<SafariWarningMessage>
				<p>
					Hello! It looks like you're trying to access our website using <span>Safari</span>.
				</p>
				<br />
				<p>
					Unfortunately, our website is currently not compatible with Safari. But no worries! You can easily access all our features and content by using other popular
					browsers such as <span>Google Chrome</span>, <span>Mozilla Firefox</span>, or <span>Opera</span>.
				</p>
				<br />
				<p>Thank you for your interest in our website, and we hope to see you soon!</p>
			</SafariWarningMessage>
			<div></div>
		</SafariWarningWrapper>
	)
}

export default SafariWarning
