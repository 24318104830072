import { Route, Routes } from "react-router-dom"
import GameLayout from "src/layouts/GameLayout"
import GamePage from "src/pages/Game"
import WithAuthHOC from "../components/WithAuthHOC"
import Page404 from "../pages/404"
import AuthPage from "../pages/Auth"
import SignIn from "src/pages/Auth/SignIn"
import SignUp from "src/pages/Auth/SignUp"
import AuthLayout from "src/layouts/AuthLayout"
import Loading from "src/components/Loading"
import SafariWarning from "src/pages/404/SafariWarning"
import GuestToBased from "src/pages/Auth/GuestToBased"
import Logout from "src/pages/Auth/Logout"
import VerifyEmail from "src/components/VerifyEmail"
import VerifyEmailSuccess from "src/components/VerifyEmail/VerifyEmailSuccess"

const AppRouter = () => {
	return (
		<Routes>
			<Route path="/auth" element={<AuthPage />} />
			<Route path="/logout" element={<Logout />} />
			<Route
				path="/signin"
				element={
					<AuthLayout>
						<SignIn />
					</AuthLayout>
				}
			/>
			<Route
				path="/signup"
				element={
					<AuthLayout>
						<SignUp />
					</AuthLayout>
				}
			/>

			<Route
				path="/signup/guest_to_based"
				element={
					<AuthLayout>
						<GuestToBased />
					</AuthLayout>
				}
			/>

			<Route
				path="/verify-email"
				element={
					<AuthLayout>
						<VerifyEmail />
					</AuthLayout>
				}
			/>

			<Route
				path="/verify-email/success"
				element={
					<AuthLayout>
						<VerifyEmailSuccess />
					</AuthLayout>
				}
			/>

			<Route
				path="/"
				element={
					<WithAuthHOC>
						<GameLayout>
							<GamePage />
						</GameLayout>
					</WithAuthHOC>
				}
			/>

			<Route path="/safari" element={<SafariWarning />} />

			<Route
				path="*"
				element={
					<AuthLayout>
						<Page404 />
					</AuthLayout>
				}
			/>
		</Routes>
	)
}

export default AppRouter
