import { keyframes, styled } from "styled-components"
import BackgroundImage from "../../assets/BackgroundFull.webp"
import BackgroundField from "../../assets/BackgroundField.png"
import BorderField from "../../assets/BorderField.png"

export const AuthPageWrapper = styled.div`
	display: grid;
	place-items: center;
	height: 100%;

	place-items: center;

	/* background:
	no-repeat center url(${BackgroundImage}),
	radial-gradient(ellipse at center, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0.8) 100%);
	box-shadow: inset 0 0 200px 200px rgba(0, 0, 0, 0.6); */
	background: no-repeat center url(${BackgroundImage});

	background-clip: padding-box;
	background-attachment: fixed;
	background-size: cover;
	background-color: ${props => props.theme.mainBackgroundColor};
	@media only screen and (max-device-width: 480px) {
		background-size: cover;
	}
`
export const AuthPageContent = styled.div`
	display: grid;
	place-items: center;
	height: 90%;
	grid-template-rows: 1fr auto 1fr;

	& > div {
		height: 100%;
		width: 100%;

		text-align: center;
	}
`

export const Logo = styled.div`
	img {
		width: 13vw;

		@media only screen and (max-device-width: 480px) {
			width: 33vw;
		}
	}
`

export const CenterBlock = styled.div`
	position: relative;
`

const fieldHeight = "100px"
const fieldMobileHeight = "130px"
export const BorderBlock = styled.div`
	background: url(${BackgroundField});

	& > img {
		max-height: ${fieldHeight};

		@media only screen and (max-device-width: 480px) {
			max-height: ${fieldMobileHeight};
		}
	}
`

export const EnterAsGuest = styled.div`
	position: absolute;
	top: 6px;

	height: ${fieldHeight};
	width: 100%;

	display: grid;
	place-items: center;

	font-size: min(10vw, 64px);

	color: white;
	cursor: pointer;

	@media only screen and (max-device-width: 480px) {
		height: ${fieldMobileHeight};
		font-size: min(10vw, 94px);
	}
`
export const SignIn = styled.div`
	font-size: 45px;
	font-weight: 400;

	background-color: rgba(255, 255, 255, 0.1);
	padding: 20px 30px;
	margin: 50px auto 0;

	width: max-content;

	color: white;
	cursor: pointer;

	@media only screen and (max-device-width: 480px) {
		font-size: 65px;
		padding: 30px 40px;
		margin: 60px auto 0;
	}
`
