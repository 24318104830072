import GameLayout from "src/layouts/GameLayout"
import { LoadingContent, LoadingWrapper } from "./styles"

const Loading = () => {
	return (
		<GameLayout>
			<LoadingWrapper>
				<LoadingContent></LoadingContent>
			</LoadingWrapper>
		</GameLayout>
	)
}

export default Loading
